body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  background-color: #57b906 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.leaflet-container {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;

  background-color: #d8e7c7;
}



.myCluster {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background-color: #169dff;
  font-weight: 500;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  color: white;
  text-align: center;
  font-size: 14px;
  line-height: 36px;
  margin-top: -20px;
  margin-left: -19px;
  box-sizing: unset;
}
.myCluster:before {
  content: ' ';
  position: absolute;
  border-radius: 50%;
  z-index: -1;
  top: -4px;
  left: -4px;
  right: -4px;
  bottom: -4px;
  border: 4px solid #39acff87;
}

.bigCluster {
  height: 40px;
  width: 40px;
  font-size: 11px;
  line-height: 41px;
}
.myclusterBIG:before {
  top: -4px;
  left: -4px;
  right: -4px;
  bottom: -4px;
}

.dylan {
  background-color: #fd9326ed; 
}
.dylan:before {
  border: 4px solid #ffa03c8a;
}

.gabin {
  background-color: #b80bffed; 
}
.gabin:before {
  background-color: #c845fc8a; 
}

.done {
  display: none;
  /* height: 29px;
  width: 29px;
  line-height: 30px;
  background-color: #6b6b6b;
  margin-left: -14px; */
}
.done:before {
  border: 4px solid #8c8c8c87;
}